<template>
    <div class="brand-page">
        <div class="section intro-section">
            <h2>相关人物</h2>
            <img
            src="/people/广.png"
            mode="scaleToFill"
        />
            <!-- <div class="page-container">
                <div class="image-section">
                    <img src="/people/6.jpg" alt="Group Image">
                </div>
                <div class="content-section">
                    <h1>广海</h1>
                    <p>
                        董朝阳，字广海、号圣玄，别号东山，又号余福。男，汉族，属鼠，1996年出生于河南南阳。少年慕佛，后于开封大相国寺披剃出家。曾任南京市溧水区佛教协会秘书长。
                        现致力于将珠宝与禅意相结合，吉祥于翡翠相融合，时尚与喜乐共发展之理念。<br>
                        中文名董朝阳，国籍中国，民族汉族，出生地河南南阳。<br>1996年毕业院校闽南佛学院，字广海，号圣玄，别号东山，信仰佛教。
                    </p>
                    
                </div>
            </div> -->
            <!-- <div class="page-container">
                <div class="content-section">
                    <h1>人物经历</h1>
                    <p>
                        2012年-2013年於河南省开封市大相国寺披剃出家并常住。
                        2013年-2015年於福建省厦门市闽南佛学院就读预科。<br>
                        2015年-2019年於福建省厦门市闽南佛学院就读本科。<br>
                        2019年-2021年於南京市溧水区无想禅寺常住。<br>
                        2021年当选为南京市溧水区佛教协会秘书长。 <br>
                        2021年-2023年於浙江省杭州市余杭区径山万寿禅寺常住。<br>
                        现任Rose Spring 联合创始人
                    </p>
                    
                </div>
                <div class="image-section">
                    <img src="/people/9.jpg" alt="Group Image">
                </div>
            </div> -->
        </div>
        <!-- <div class="page-container">
            <div class="image-section">
                <img src="/people/1.jpg" alt="Group Image">
            </div>
            <div class="content-section">
                <h1>个人生活</h1>
                <p>
                    出家时常以“三乐”示众，一、独处自诵经，二、开门迎佳客，三、出门寻山水。
                    又以“三喜”自乐，一、读一些增智的好书，二、结一些可结的善缘，三、渡一些苦难的人生。
                </p>
                
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    name: 'Guang'
}
</script>

<style scoped>
/* Basic container styles */
.brand-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #faf8f5;
    /* Light background color similar to the example */
}

/* Intro Section Styles */
.intro-section {
    text-align: center;
    margin-bottom: 40px;
}

.intro-section h2 {
    font-size: 2.0em;
    font-weight: bold;
    color: #4a4a4a;
    margin-bottom: 20px;
}

.intro-section p {
    font-size: 1.0em;
    line-height: 1.8;
    color: #4a4a4a;
}

/* Spirit Section Styles */
.spirit-section {
    text-align: center;
}

.spirit-section h3 {
    font-size: 2em;
    font-weight: bold;
    color: #4a4a4a;
    margin-bottom: 20px;
}

.spirit-section p {
    font-size: 1.2em;
    line-height: 1.8;
    color: #4a4a4a;
    margin-bottom: 20px;
}

.learn-more {
    display: inline-block;
    margin-top: 20px;
    font-size: 1.2em;
    color: #0073e6;
    text-decoration: none;
    border-bottom: 1px solid #0073e6;
}

.image-container {
    margin-top: 200px;
    text-align: center;
}

.image-container img {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .brand-page {
        padding: 10px;
    }

    .intro-section h2,
    .spirit-section h3 {
        font-size: 1.8em;
    }

    .intro-section p,
    .spirit-section p {
        font-size: 1em;
    }

    .learn-more {
        font-size: 1em;
    }
}

.page-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    background-color: #f8f3ec;
    /* Set background color similar to the image */
}

.content-section {
    max-width: 50%;
    /* The content section takes up 50% of the width */
}

.content-section h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.content-section p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

.learn-more {
    font-size: 1.1rem;
    color: #000;
    text-decoration: none;
    border-bottom: 1px solid #000;
    /* Underline effect */
    padding-bottom: 2px;
}

.learn-more:hover {
    color: #555;
    /* Darken the text color on hover */
    border-color: #555;
    /* Change underline color on hover */
}

.image-section {
    max-width: 45%;
    /* The image section takes up 45% of the width */
}

.image-section img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
</style>