<template>
  <div class="contact-page">
    <div class="header">
      <el-link icon="el-icon-arrow-left" @click="goBack">返回首页</el-link>
    </div>
    <div class="content" style="background-color: #f0d9d8;">
      <h1 style="margin-bottom: 10px;">联系我们</h1>
      <hr>
      <div class="contact-info">
        <div class="service-center">
          <h2> 客服服务中心</h2>
          <p>客服电话：057186603887<br>
            400-088-9668
          </p>
          <p>周一至周日 10点 - 22点</p>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;">
          <img style="width: 5vh;margin-left: -0.5vh;" src="/tele/微信.png" />
          <div style="padding-right: 180vh;">微信客服</div>
        </div>
        <div class="wechat-service">
          <p>请用微信扫描二维码添加，即可咨询在线客服。</p>
          <img style="margin-left: -0.5%;" src="/tele/kefu.png" alt="WeChat QR Code" />
        </div>

        <div style="width: 20vh;display: flex;flex-direction: row;">
          <a
            href="https://www.douyin.com/user/MS4wLjABAAAAvTl3YxOmPn8r8lusi6xLni_l29O4f_PUVuT386TmddwNc_HOjB_PA0jZ4Tf8R9XI?from_tab_name=main">
            <img src="/logo/9.png" />
          </a>
          <a
            href="https://www.douyin.com/user/MS4wLjABAAAAvTl3YxOmPn8r8lusi6xLni_l29O4f_PUVuT386TmddwNc_HOjB_PA0jZ4Tf8R9XI?from_tab_name=main">
            <img src="/logo/10.png" />
          </a>
        </div>
        <br class="additional-info">

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tele",
  methods: {
    goBack() {
      this.$router.push("/");
    }
  }
};
</script>

<style scoped>
.contact-page {
  font-family: 'Arial', sans-serif;
  background-color: #faf8f6;
  padding: 20px;
}

.header {
  margin-bottom: 20px;
}

.back-link {
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

.content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

h1 {
  font-size: 28px;
  color: #333;
}

.contact-info {
  margin-top: 20px;
}

.service-center h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.service-center p {
  font-size: 16px;
  color: #666;
  margin: 2px 0;
}

.wechat-service p {
  font-size: 16px;
  color: #666;
  margin: 2px 0;
}

.wechat-service img {
  width: 150px;
  height: 150px;
  margin-top: 10px;
}

.additional-info {
  font-size: larger;
  color: #999;
  margin-top: 20px;
  line-height: 1.6;
}
</style>