<template>
    <div>
        <H1 style="margin-top: 10vh;margin-left: 10vh;margin-right: 10vh;">In the News</H1>
        <hr style="margin-top: 5vh;margin-left: 10vh;margin-right: 10vh;">
        <div v-for="item in list" :key="item.id" style="display: flex; align-items: flex-start; margin-bottom: 20px;">
            <div style="display: flex;flex-direction: row;margin-top: 10vh;margin-left: 10vh;margin-right: 10vh;"> 
                <img :src="item.image" style="height:200px;width: 200px;">
                <div style="margin-left: 20px;display: flex; flex-direction: column;justify-content: space-between;" >
                    <a class="title"  :href="item.intro">{{ item.title }}</a>
                    <a style="font-size: x-large;" >{{ item.summary }}</a>
                    <p>{{ item.createTime }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'message',
    data() {
        return {
            message: '',
            imageUrls: [],
            videoUrls: [],
            list: [
                {
                    image:'/zixun/2.png',
                    intro:'https://mp.weixin.qq.com/s/OBdGe7D3aXH2smxnAtrCrQ',
                    title:'Rose Spring •点茶篇',
                    summary:'径山茶宴”作为“中国传统制茶技艺及其相关习俗”重要组成部分，于2022年11月列入联合国教科文组织人类非物质文化遗产代表作名录。',
                    createTime:'2024-09-03 15:21:59'


                }
            ],
            content: ''
        }
    },
    computed: {
    },
    // async mounted() {
    //     this.fetchData();
    // },
    methods: {
        // async fetchData() {
        //     try {
        //         const response = await fetch('/api/article/list?cid=4');
        //         const data = await response.json(); // Parse JSON data
        //         this.list = data.data.lists;
        //         console.log(this.list);
        //     } catch (error) {
        //         console.error('Error fetching data:', error);
        //     }
        // },
    }
}
</script>
<style scoped>
.title {
    font-size: xx-large;
    color: black;
    text-decoration: none; /* Ensure the link has no underline */
    transition: color 0.3s; /* Optional: Add a smooth transition */
    position: relative;
    display: inline-block;
    padding-bottom: 2px;
}
.title:hover {
    color: #8d3939; /* Change the color on hover */
}
.title::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #8d3939; /* Color of the underline */
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.title:hover::after {
    visibility: visible;
    width: 30%;
}
</style>