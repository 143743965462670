<template>
  <div class="product-gallery">
    <h2 class="gallery-title">产品示例</h2>
    <div class="image-gallery">
      <img v-for="(url, index) in imageUrls" :key="index" :src="url" alt="Image" class="gallery-image" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'product',
  data() {
    return {
      message: '',
      imageUrls: [
        '/product/9.jpg',
        '/product/10.jpg',
        '/product/11.jpg',
        '/product/12.jpg',
        '/product/13.jpg',
        '/product/15.jpg',
        '/product/16.jpg',
        '/product/17.jpg',
        '/product/18.jpg',
        '/product/19.jpg',
        '/product/1.png',
        '/product/3.png',
        '/product/4.png',
        '/product/7.png',
        '/product/f1.jpg',
        '/product/f2.jpg',
        '/product/f3.jpg',
        '/product/f4.jpg',
        '/product/f5.jpg',
        '/product/f6.jpg',
        '/product/f7.jpg',
        '/product/f8.jpg',
      ], 
      videoUrls: [],
      content: ''
    };
  },
  // async mounted() {
  //   await this.fetchData();
  //   this.extractImageUrls(); 
  // },
  methods: {
    alter() {
      this.$message({
        message: '居中的文字',
        center: true
      });
    },
    // async fetchData() {
    //   try {
    //     const response = await fetch('/api/pc/articleDetail?id=5');
    //     const data = await response.json(); // Parse JSON data
    //     if (data && data.code === 200) {
    //       this.content = data.data.content;
    //       console.log(this.content);
    //     } else {
    //       console.error('接口返回错误:', data.msg);
    //     }
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // },
    // extractImageUrls() {
    //   const parser = new DOMParser();
    //   const doc = parser.parseFromString(this.content, 'text/html');
    //   const imgLinks = Array.from(doc.querySelectorAll('img')).map(img => img.src);
    //   this.imageUrls = imgLinks; // Update imageUrls with extracted image links
    //   console.log(this.imageUrls);
    // }
  }
};
</script>

<style scoped>
.product-gallery {
  background-color: #f9f5f0;
  /* Softer background color */
  padding: 40px 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.gallery-title {
  text-align: center;
  padding-bottom: 30px;
  font-size: 28px;
  color: #333;
  font-weight: 600;
  letter-spacing: 1px;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
  /* Increased gap for better spacing */
}

.gallery-image {
  width: calc(25% - 15px);
  /* Adjust width according to the gap */
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  /* Rounded corners for a polished look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Soft shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-image:hover {
  transform: translateY(-5px);
  /* Slight lift on hover for interaction */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .gallery-image {
    width: calc(50% - 15px);
  }
}

@media (max-width: 480px) {
  .gallery-image {
    width: 100%;
  }
}
</style>