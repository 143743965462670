<template>
    <div style="display: flex;flex-direction: column;align-items: center;height: 100%;">
        <div
            style="font-size: x-large;text-align: center;margin-top: 5vh;font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;font-style: italic;">
            自创立以来，RoseSpring（玫瑰春天）<br>
            一直被誉为中国的奢侈品牌的优雅典范
        </div>
        <div style="text-align: center;margin-top: 5%;">创始人以其独到眼光和深厚玄学因缘，奠定品牌基石，<br>镶嵌师则以精湛技艺和对风水之妙的独到理解，赋予每件作品以灵魂，传承玄妙之艺。
        </div>
        <p style="text-align: center;font-size: smaller;margin-top: 5vh;color: gray;">人才库</p>
        <div style="display: flex;flex-direction: row;width: 100%;margin-top: 5vh;justify-content: center;">
            <div style="display: flex;flex-direction: column;align-items: center;width: 20%;margin-right: 10%;">
                <img src="/people/1.jpg" style="width: 30vh;margin-bottom: 2vh;" />
                <router-link style="color: grey;text-decoration: none;" to="/guang">
                    <p>作为品牌的联合创始人，为品牌的诞生与发展注入了独特的灵魂，创造了兼具美学价值与灵性寓意的珠宝作品。</p>
                </router-link>
            </div>
            <div style="display: flex;flex-direction: column;align-items: center;width: 20%;">
                <img src="/people/longfeng.jpg" style="width: 31.5vh;margin-bottom: 2vh;" />
                <router-link style="color: grey;text-decoration: none;" to="/long">
                    <p>作为RoseSpring的首席镶嵌师，以其精湛的工艺为每一件珠宝作品赋予生命与灵魂，让佩戴者感受到珠宝所蕴含的宇宙能量。</p>
                </router-link>
            </div>
        </div>

    </div>

</template>

<script>
export default {
    name: 'People'
}
</script>

<style scoped></style>