<template>
  <div id="app" style="background-color: #f0d9d8;">
    <div style="opacity: 0.6;">
      <header>
        <div class="logo" style="margin-left: 5%;">
          <h1>Rose Spring</h1>
          <p style="">玫瑰春天</p>
        </div>
        <nav style="margin-right: 5%;">
          <ul>
            <li @click="alter()"><router-link to="/Vogue">惊喜</router-link></li>
            <li><router-link to="/product">产品示例</router-link></li>
            <li><router-link to="/message">最新资讯</router-link></li>
            <!-- <li><router-link to="/brand">品牌</router-link></li> -->
            <li><router-link to="/people">人才库</router-link></li>
            <li><router-link to="/tele">联系我们</router-link></li>
          </ul>
        </nav>
      </header>
    </div>

    <img v-if="isWeChat" class="video" src="/image/index.png" mode="scaleToFill" />
    <video v-else class="video" autoplay loop="true">
      <source src="/image/1.mp4" type="video/mp4" muted>
      您的浏览器不支持视频标签。
    </video>
    <div class="brand-page">
    <div class="section intro-section">
      <div class="page-container">
        <div class="content-section">
          <h1>品牌的起源与早期发展</h1>
          <p>
            2016年成立诞生，前身为春天珠宝，初期以明星定制佩戴为主，因其独一无二的设计风格，独树一帜的时尚感，砾励前行的热爱，孜孜不倦的创新，迅速在业内声名鹊起，吸引一众明星青睐与追寻。
          </p>
          <!-- <a href="#" class="learn-more">了解更多</a> -->
        </div>
        <div class="image-section">
          <img src="/brand/pro1.png" alt="Group Image">
        </div>
      </div>
    </div>
    <div class="page-container">
      <div class="image-section">
        <img src="/brand/pro3.webp" alt="Group Image">
      </div>
      <div class="content-section">
        <h1>品牌理念与愿景</h1>
        <p>
          世间每个故事，都有个暖人心的开头，春天珠宝亦是如此。我们希望冬天缺失的，春天可以补回来，冬去春来，苦尽甘来。后期的春天珠宝，不仅服务于明星豪门，并且沉浸于个人的私人定制，希翼为每个喜欢尊贵与时尚的客户，创造不一样的自己。
        </p>
        <!-- <a href="#" class="learn-more">了解更多</a> -->
      </div>
    </div>
    <div class="page-container">
      <div class="content-section">
        <h1>珠宝品牌的专业性与工艺</h1>
        <p>
          作为集全球化的原石甄选、采购、设计、加工、定制、高端于一体的珠宝品牌，我们专注于翡翠、彩钻及白钻等珍稀宝石的镶嵌艺术，给你专属的爱与美。在岁月的沉淀中，汲取自然之灵韵，融合国际顶尖设计与工艺，匠心独运，打造传世之作。以卓越服务赢得客户信赖，引领私人定制新风尚，致力于让每一件作品都成为佩戴者情感与身份的象征，见证生命中每一个美丽时刻。
        </p>
        <!-- <a href="#" class="learn-more">了解更多</a> -->
      </div>
      <div class="image-section">
        <img src="/brand/pro4.webp" alt="Group Image">
      </div>
    </div>
    <div class="page-container">
      <div class="image-section">
        <img src="/brand/pro5.webp" alt="Group Image">
      </div>
      <div class="content-section">
        <h1>品牌在国内的影响力与扩展</h1>
        <p>
          现在国内北京、天津、杭州、义乌等地，开设顶级珠宝会所十四所，并成功举办多场珠宝私人鉴赏会，在影视圈及高端社交圈中树立了独特的品牌形象。
        </p>
        <!-- <a href="#" class="learn-more">了解更多</a> -->
      </div>
    </div>
    <div class="page-container">
      <div class="content-section">
        <h1>多元化的产业布局与未来愿景</h1>
        <p>
          另外公司还涉及影视、酒店、风投等产业，将和RoseSpring共同演绎时代的潮流与文化。我们将继续秉承“打造中国私人定制珠宝领导品牌”的愿景，以匠心、创新与跨界融合，为每一位追求美好生活的您，定制专属的璀璨梦想。
        </p>
        <!-- <a href="#" class="learn-more">了解更多</a> -->
      </div>
      <div class="image-section">
        <img src="/brand/pro6.jpeg" alt="Group Image">
      </div>
    </div>
  </div>
    <!-- Footer Section -->
    <div class="footer"
      style="background-color: #f0d9d8; color: #fff; padding: 20px; display: flex; justify-content: space-between; align-items: center;">
      <div class="footer-left" style="max-width: 60%;padding-left: 6%;">
        <p style="font-size: smaller;"> 劳斯铂润（杭州）珠宝有限公司</p>
        <p style="font-size: smaller;">联系电话：057186603887</p>
        <p style="font-size: smaller;">浙ICP备2022000096号-1</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'home',
  data() {
    return {
      message: '',
      imageUrls: [],
      videoUrls: [],
      content: '',
      isWeChat: false,
    }
  },
  computed: {
    // 计算属性截取后8个图片链接
    displayedImages() {
      return this.imageUrls.slice(-8);
    }
  },
  mounted() {
    this.checkWeChat();
  },
  // async mounted() {
  //   await this.fetchData();
  //   this.extractImageUrls(); 
  // },
  methods: {

    checkWeChat() {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.includes('micromessenger')) {
        this.isWeChat = true;
      }
    },

    // async fetchData() {
    //   try {
    //     const response = await fetch('/api/pc/articleDetail?id=6');
    //     const data = await response.json(); 
    //     if (data && data.code === 200) {
    //       this.content = data.data.content;
    //       console.log(this.content);
    //     } else {
    //       console.error('接口返回错误:', data.msg);
    //     }
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // },
    // extractImageUrls() {
    //   const parser = new DOMParser();
    //   const doc = parser.parseFromString(this.content, 'text/html');
    //   const imgLinks = Array.from(doc.querySelectorAll('img')).map(img => img.src);
    //   this.imageUrls = imgLinks; 
    //   console.log(this.imageUrls);
    // }
  }
}
</script>

<style lang="scss">
nav ul li a,
nav ul li router-link {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  position: relative;
  padding-bottom: 5px;
}

nav ul li a::after,
nav ul li router-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: rgb(13, 47, 73);
  transition: width 0.3s ease;
}

nav ul li a:hover::after,
nav ul li router-link:hover::after {
  width: 100%;
}

img {
  display: block;
  width: 80vw;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
}

.container {
  overflow: hidden;
  width: 80vw;
  height: 80vh;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

header {

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #000;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f0d9d8;
  pointer-events: auto;
  z-index: 1000;

}

.video {
  margin-top: 80px;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.logo h1 {

  font-size: 30px;
  margin-bottom: 5px;
}

.logo p {
  font-size: 18px;
  color: #555;
}

nav ul {
  display: flex;
  list-style-type: none;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-right a {
  text-decoration: none;
  color: #000;
  margin-left: 15px;
  font-size: 16px;
}

.icon {
  width: 20px;
  height: 20px;
}

.brand-page {
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  background-color: #faf8f5;
  /* Light background color similar to the example */
}

/* Intro Section Styles */
.intro-section {
  text-align: center;
  margin-bottom: 40px;
}

.intro-section h2 {
  font-size: 2.0em;
  font-weight: bold;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.intro-section p {
  font-size: 1.0em;
  line-height: 1.8;
  color: #4a4a4a;
}

/* Spirit Section Styles */
.spirit-section {
  text-align: center;
}

.spirit-section h3 {
  font-size: 2em;
  font-weight: bold;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.spirit-section p {
  font-size: 1.2em;
  line-height: 1.8;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.learn-more {
  display: inline-block;
  margin-top: 20px;
  font-size: 1.2em;
  color: #0073e6;
  text-decoration: none;
  border-bottom: 1px solid #0073e6;
}

.image-container {
  margin-top: 200px;
  text-align: center;
}

.image-container img {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .brand-page {
    padding: 10px;
  }

  .intro-section h2,
  .spirit-section h3 {
    font-size: 1.8em;
  }

  .intro-section p,
  .spirit-section p {
    font-size: 1em;
  }

  .learn-more {
    font-size: 1em;
  }
}

.page-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: #f8f3ec;
  /* Set background color similar to the image */
}

.content-section {
  max-width: 50%;
  /* The content section takes up 50% of the width */
}

.content-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.content-section p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.learn-more {
  font-size: 1.1rem;
  color: #000;
  text-decoration: none;
  border-bottom: 1px solid #000;
  /* Underline effect */
  padding-bottom: 2px;
}

.learn-more:hover {
  color: #555;
  /* Darken the text color on hover */
  border-color: #555;
  /* Change underline color on hover */
}

.image-section {
  max-width: 45%;
  /* The image section takes up 45% of the width */
}

.image-section img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
</style>