import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/home.vue'; // Adjust the path if necessary
import Brand from '@/views/brand.vue';
import Vogue from '@/views/vogue.vue'; // Ensure this path is correct
import Message from '@/views/message.vue'; // Ensure this path is correct
import Product from '@/views/product.vue'; // Ensure this path is correct
import Tele from '@/views/tele.vue';
import People from '@/views/people.vue';
import Guang from '@/views/guang.vue';
import Long from '@/views/long.vue';
Vue.use(Router);

export default new Router({
  mode: 'history', // Optional: use 'hash' mode if you prefer URLs with hashes
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/tele',
      name: 'Tele',
      component: Tele,
    },

    {
      path: '/brand',
      name: 'Brand',
      component: Brand,
    },
    {
        path: '/vogue',
        name: 'Vogue',
        component: Vogue,
    },
    {
        path: '/message',
        name: 'Message',
        component: Message,
    },
    {
        path: '/product',
        name: 'Product',
        component: Product,
    },
    {
      path: '/people',
      name: 'People',
      component: People,
  },
  {
    path: '/guang',
    name: 'Guang',
    component: Guang,
},
{
  path: '/long',
  name: 'Long',
  component: Long,
},
  ],
});
