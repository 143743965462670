<template>
  <div id="app">
    <router-view></router-view> <!-- This is where your home.vue or other components will be rendered -->
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">
/* Your global styles here */
</style>
