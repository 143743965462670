<template>
  <div class="brand-page">
    <div class="section intro-section">
      <p>关于Rose Spring</p>
      <h2>冬天缺失的 &nbsp;春天给你补回来</h2>
      <div class="page-container">

        <div class="content-section">
          <h1>品牌的起源与早期发展</h1>
          <p>
            2016年成立诞生，前身为春天珠宝，初期以明星定制佩戴为主，因其独一无二的设计风格，独树一帜的时尚感，砾励前行的热爱，孜孜不倦的创新，迅速在业内声名鹊起，吸引一众明星青睐与追寻。
          </p>
          <!-- <a href="#" class="learn-more">了解更多</a> -->
        </div>
        <div class="image-section">
          <img src="/brand/pro1.png" alt="Group Image">
        </div>
      </div>

    </div>
    <div class="page-container">
      <div class="image-section">
        <img src="/brand/pro3.webp" alt="Group Image">
      </div>
      <div class="content-section">
        <h1>品牌理念与愿景</h1>
        <p>
          世间每个故事，都有个暖人心的开头，春天珠宝亦是如此。我们希望冬天缺失的，春天可以补回来，冬去春来，苦尽甘来。后期的春天珠宝，不仅服务于明星豪门，并且沉浸于个人的私人定制，希翼为每个喜欢尊贵与时尚的客户，创造不一样的自己。
        </p>
        <!-- <a href="#" class="learn-more">了解更多</a> -->
      </div>
    </div>


    <div class="page-container">
      <div class="content-section">
        <h1>珠宝品牌的专业性与工艺</h1>
        <p>
          作为集全球化的原石甄选、采购、设计、加工、定制、高端于一体的珠宝品牌，我们专注于翡翠、彩钻及白钻等珍稀宝石的镶嵌艺术，给你专属的爱与美。在岁月的沉淀中，汲取自然之灵韵，融合国际顶尖设计与工艺，匠心独运，打造传世之作。以卓越服务赢得客户信赖，引领私人定制新风尚，致力于让每一件作品都成为佩戴者情感与身份的象征，见证生命中每一个美丽时刻。
        </p>
        <!-- <a href="#" class="learn-more">了解更多</a> -->
      </div>
      <div class="image-section">
        <img src="/brand/pro4.webp" alt="Group Image">
      </div>
    </div>





    <div class="page-container">
      <div class="image-section">
        <img src="/brand/pro5.webp" alt="Group Image">
      </div>
      <div class="content-section">
        <h1>品牌在国内的影响力与扩展</h1>
        <p>
          现在国内北京、天津、杭州、义乌等地，开设顶级珠宝会所十四所，并成功举办多场珠宝私人鉴赏会，在影视圈及高端社交圈中树立了独特的品牌形象。
        </p>
        <!-- <a href="#" class="learn-more">了解更多</a> -->
      </div>
    </div>





    <div class="page-container">
      <div class="content-section">
        <h1>多元化的产业布局与未来愿景</h1>
        <p>
          另外公司还涉及影视、酒店、风投等产业，将和RoseSpring共同演绎时代的潮流与文化。我们将继续秉承“打造中国私人定制珠宝领导品牌”的愿景，以匠心、创新与跨界融合，为每一位追求美好生活的您，定制专属的璀璨梦想。
        </p>
        <!-- <a href="#" class="learn-more">了解更多</a> -->
      </div>
      <div class="image-section">
        <img src="/brand/pro6.jpeg" alt="Group Image">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Brand'
}
</script>

<style scoped>
/* Basic container styles */
.brand-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #faf8f5;
  /* Light background color similar to the example */
}

/* Intro Section Styles */
.intro-section {
  text-align: center;
  margin-bottom: 40px;
}

.intro-section h2 {
  font-size: 2.0em;
  font-weight: bold;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.intro-section p {
  font-size: 1.0em;
  line-height: 1.8;
  color: #4a4a4a;
}

/* Spirit Section Styles */
.spirit-section {
  text-align: center;
}

.spirit-section h3 {
  font-size: 2em;
  font-weight: bold;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.spirit-section p {
  font-size: 1.2em;
  line-height: 1.8;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.learn-more {
  display: inline-block;
  margin-top: 20px;
  font-size: 1.2em;
  color: #0073e6;
  text-decoration: none;
  border-bottom: 1px solid #0073e6;
}

.image-container {
  margin-top: 200px;
  text-align: center;
}

.image-container img {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .brand-page {
    padding: 10px;
  }

  .intro-section h2,
  .spirit-section h3 {
    font-size: 1.8em;
  }

  .intro-section p,
  .spirit-section p {
    font-size: 1em;
  }

  .learn-more {
    font-size: 1em;
  }
}

.page-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: #f8f3ec;
  /* Set background color similar to the image */
}

.content-section {
  max-width: 50%;
  /* The content section takes up 50% of the width */
}

.content-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.content-section p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.learn-more {
  font-size: 1.1rem;
  color: #000;
  text-decoration: none;
  border-bottom: 1px solid #000;
  /* Underline effect */
  padding-bottom: 2px;
}

.learn-more:hover {
  color: #555;
  /* Darken the text color on hover */
  border-color: #555;
  /* Change underline color on hover */
}

.image-section {
  max-width: 45%;
  /* The image section takes up 45% of the width */
}

.image-section img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
</style>
